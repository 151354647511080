<template>
  <div
    class="app-background"
    :style="{ 'background-image': 'url(' + backgroundImage + ')' }"
  >
    <div class="organization-card">
      <img alt="logo" class="logo" :src="logo" />
      <h3 class="heading">Which organization do you want to access?</h3>
      <ul class="organization-list">
        <li
          v-for="organization in userOrganizations"
          :key="organization.id"
          @click="
            selectOrganization({
              organization: organization.organization,
            })
          "
        >
          {{ organization.organization }}
        </li>
      </ul>
      <div class="my-3">
        <button class="btn btn-primary" @click="onLogout">Logout</button>
      </div>
    </div>
  </div>
</template>

<script>
import { getAzureLoginStatus } from 'src/utils/auth';
import { useToast } from 'vue-toastification';
import { mapActions, mapGetters, useStore } from 'vuex';
export default {
  setup() {
    const store = useStore();
    const rules = {
      email: [
        {
          required: true,
          type: 'email',
          trigger: 'submit',
          message: 'email is required',
        },
      ],
      password: [
        { required: true, trigger: 'submit', message: 'password is required' },
      ],
    };

    const toast = useToast();

    return {
      rules,
      logo: require('src/assets/img/assembly-cp-logo.png'),
      backgroundImage: require('src/assets/img/backgroundImage.png'),
      store,
      toast,
    };
  },
  computed: {
    ...mapGetters([
      'msalInstance',
      'accessToken',
      'receiveOtpOn',
      'isOtpSetup',
      'userOrganizations',
      'email',
      'role',
    ]),
    hasOrganizations() {
      return this.userOrganizations && this.userOrganizations.length > 0;
    },
  },
  components: {},
  data() {
    return {
      showModal: false,
      showVerifyRoute: false,
      account: undefined,
      form: {
        email: '',
        password: '',
      },
    };
  },
  watch: {
    userOrganizations: {
      deep: true,
      handler() {
        this.showModal = false;
      },
    },
  },
  async mounted() {
    await this.fetchOrganizations();
  },
  methods: {
    ...mapActions([
      'resetState',
      'resetDeviceState',
      'userLogin',
      'createMsalInstance',
      'azureSignIn',
      'googleSignIn',
      'azureSignUp',
      'userLogout',
      'clearState',
      'selectOrganization',
      'fetchOrganizations',
    ]),
    onLogout() {
      this.resetState();
      this.resetDeviceState();
      if (getAzureLoginStatus()) {
        this.AzureSignOut();
      } else {
        this.clearState({ fromBasePage: true, logout: true });
      }
    },
    async AzureSignOut() {
      this.clearState();
    },
    async onSubmit() {
      let UserInfo = await this.userLogin({
        email: this.form.email,
        password: this.form.password,
      });

      if (UserInfo && UserInfo.require_otp) {
        this.showModal = true;
      }
      this.showVerifyRoute = true;
    },
    ModalClose() {
      this.clearState();
      this.showModal = false;
    },
    async AzureSignIn() {
      await this.msalInstance
        .loginPopup({
          scopes: ['openid', 'profile', 'User.Read', 'email'],
          prompt: 'select_account',
        })
        .then((res) => {
          const myAccounts = this.msalInstance.getAllAccounts();
          const payload = {
            access_token: res.accessToken,
          };
          this.account = myAccounts[0];
          this.msalInstance.setActiveAccount(this.account);
          this.azureSignIn(payload);
        })
        .catch((error) => {
          console.error(`error during authentication: ${error}`);
          this.store.dispatch('clearState');
        });
    },
    GoogleSignIn(response) {
      const payload = {
        access_token: response.credential,
      };
      this.googleSignIn(payload);
    },
  },
};
</script>
<style type="text/css">
.organization-card {
  width: 450px;
  margin: auto;
  text-align: center;
  background: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 16px;
  padding: 20px 40px;
  opacity: 0.95; /* Slightly more opaque */
  transition: transform 0.3s, box-shadow 0.3s; /* Smooth transitions for hover effect */
}

.organization-card:hover {
  transform: translateY(-5px); /* Lift effect on hover */
  box-shadow: rgba(0, 0, 0, 0.5) 0px 10px 20px; /* Darker shadow on hover */
}

.logo {
  width: 250px;
  height: 60px;
  margin-bottom: 10px;
}
.heading {
  font-size: 1.2rem; /* Reduced font size for the heading */
  margin-bottom: 20px; /* Space between heading and list */
}
.user-form {
  width: 80%;
  margin-top: 20px;
}

.organization-list {
  list-style-type: none; /* Remove default bullet points */
  padding: 0; /* Remove default padding */
  margin: 0; /* Remove default margin */
  max-height: 400px; /* Set a max height for the list */
  overflow-y: auto; /* Enable vertical scroll if content exceeds max height */
  overflow-x: hidden; /* Hide horizontal scrollbar if it appears */
  border-radius: 8px; /* Rounded corners for list */
  padding-right: 10px; /* Space for scrollbar */
}

.organization-list li {
  background-color: #f0f4f8; /* Light background for list items */
  border-radius: 8px; /* Rounded corners */
  padding: 12px 20px; /* Increased padding for comfort */
  margin: 10px 0; /* Space between items */
  transition: background-color 0.3s, transform 0.2s; /* Smooth transition for hover effect */
  cursor: pointer; /* Change cursor on hover */
  font-size: 1.1rem; /* Slightly larger font for better readability */
}

.organization-list li:hover {
  background-color: #e0e7ee; /* Darker shade on hover */
  transform: scale(1.02); /* Slight scale effect on hover */
}

.app-background::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}

.app-background {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-size: cover;
  position: relative;
  background-position: center;
}
</style>
